import React from "react"

import './CaseStudy.css';
import LearningSynthsData from './learning-synths.json'
import MobileMusicMakingData from './mobile-music-making.json'

export const CaseStudy = (props) => {
  
  let data = LearningSynthsData
  if (props.id === "mobile-beatmaking"){
    data = MobileMusicMakingData
  }

  return (
    
    <div className="page casestudy">
        
      <div className="page-content">

        <div className="grid">
          <h1 className="title col-start-1 col-end-6">{data.title}</h1>
          <p className="info" dangerouslySetInnerHTML={{__html: data.info}} />
          
          {data.assets && data.assets.map((asset, assetIndex) => {
              if (asset.type === "interstitial") {
                return (
                  <section className={"interstitial"} key={"a" + assetIndex}>
                    <h3 className="title">{asset.title}</h3>
                    {asset.info &&
                    <div className="info" dangerouslySetInnerHTML={{__html: asset.info}}/>
                    }
                  </section>
                )
              } else {
                return (
                <figure className={asset.classes} key={"a" + assetIndex}>
                    {asset.source.substr(asset.source.length - 3) === "mp4" &&
                    <video width="100%" autoPlay={true} loop={true} muted={true} inline={""}>
                        <source src={data.assetPath + asset.source} type="video/mp4" />
                    </video>
                    }
                    {asset.source.substr(asset.source.length - 3) === "png" &&
                        <img src={data.assetPath + asset.source} alt="" width={asset.width} />
                    }
                    {asset.source.substr(asset.source.length - 3) === "jpg" &&
                        <img src={data.assetPath + asset.source} alt="" />
                    }
                    {asset.caption &&
                        <figcaption className="caption" dangerouslySetInnerHTML={{__html: asset.caption}} />
                    }
                </figure>
                )}
          })}
          {data.links && data.links.map((link, index) => {
                return (
                <section className={"links"} key={"a" + index}>
                    <div key={index} className={link.classes ? link.classes : ""}>
                        <a className="animated-underline" href={link.href}>{link.title}</a>
                    </div>
                </section>
                )
            })}
        </div>

      </div>
      
    </div>
  )
}
 

import React from 'react';
import styled from "styled-components";

export const Seal = () => {
    return (
        <Wrap>
            <svg width="192" height="192" viewBox="0 0 192 192">
            
<path fillRule="evenodd" clipRule="evenodd" d="M96 1.97938C44.0738 1.97938 1.97938 44.0738 1.97938 96C1.97938 147.926 44.0738 190.021 96 190.021C147.926 190.021 190.021 147.926 190.021 96C190.021 44.0738 147.926 1.97938 96 1.97938ZM0 96C0 42.9807 42.9807 0 96 0C149.019 0 192 42.9807 192 96C192 149.019 149.019 192 96 192C42.9807 192 0 149.019 0 96Z"/>
<path d="M116.628 21.1035L117.985 21.5319C117.842 22.3252 117.953 23.0551 119.231 23.4517C120.064 23.7135 120.802 23.4359 121.024 22.7378C121.246 22.0396 120.976 21.6668 119.81 21.0797C118.08 20.2706 117.39 19.4535 117.834 18.0334C118.223 16.7959 119.572 16.193 121.151 16.6927C122.777 17.2005 123.523 18.2476 123.214 19.747L121.92 19.3424C122.032 18.5094 121.682 18.0255 120.818 17.7558C119.961 17.4861 119.389 17.7637 119.207 18.3428C119.016 18.9537 119.175 19.3424 120.429 19.9453C122.127 20.7465 122.888 21.5399 122.42 23.0551C122.008 24.364 120.579 25.0463 118.905 24.5227C116.826 23.8643 116.287 22.5712 116.628 21.1035Z"/>
<path d="M127.341 20.2467L125.302 19.3979L125.754 18.3269L131.197 20.6037L130.745 21.6747L128.706 20.8258L126.095 27.0692L124.723 26.498L127.341 20.2467Z"/>
<path d="M133.371 21.6588L135.711 22.9202C137.243 23.7452 137.981 24.951 137.211 26.3711L137.187 26.4107C136.6 27.5055 135.616 27.6959 134.624 27.45L134.965 31.5196L133.561 30.766L133.252 26.966L132.418 26.5218L130.903 29.338L129.602 28.6399L133.371 21.6588ZM133.974 26.1489C134.838 26.617 135.497 26.4901 135.902 25.7523L135.926 25.7126C136.354 24.9114 136.005 24.3719 135.18 23.9277L134.132 23.3644L132.934 25.5857L133.974 26.1489Z"/>
<path d="M140.718 25.8317L141.964 26.6329L137.672 33.2967L136.426 32.4955L140.718 25.8317Z"/>
<path d="M144.463 28.2988L146.502 29.8378C147.923 30.9088 148.375 32.2336 147.367 33.5664L147.344 33.5981C146.336 34.9309 144.923 34.796 143.567 33.7727L142.678 33.1063L140.861 35.5179L139.687 34.6294L144.463 28.2988ZM144.178 32.908C144.955 33.495 145.661 33.4315 146.185 32.7334L146.209 32.7017C146.78 31.948 146.558 31.3213 145.804 30.7581L144.947 30.1076L143.321 32.2654L144.178 32.908Z"/>
<path d="M150.717 33.2015L154.383 36.5414L153.597 37.3981L151.026 35.0499L149.566 36.6604L151.629 38.5405L150.884 39.3576L148.821 37.4775L147.257 39.191L149.971 41.6661L149.186 42.5229L145.385 39.0562L150.717 33.2015Z"/>
<path d="M158.778 41.1504L160.413 43.1099C161.547 44.4744 161.69 45.8706 160.413 46.9336L160.381 46.9653C159.096 48.0363 157.747 47.5841 156.668 46.2752L155.961 45.4263L153.644 47.3541L152.7 46.2196L158.778 41.1504ZM157.453 45.5771C158.08 46.3307 158.778 46.4259 159.453 45.8626L159.484 45.8309C160.206 45.228 160.135 44.5695 159.532 43.8476L158.842 43.0226L156.763 44.752L157.453 45.5771Z"/>
<path d="M163.769 47.37L165.261 49.5754C166.237 51.0192 166.332 52.4234 164.999 53.3277L164.959 53.3515C163.936 54.0497 162.976 53.7641 162.206 53.0897L160.619 56.858L159.722 55.5331L161.214 52.0188L160.682 51.2413L158.032 53.0421L157.207 51.8205L163.769 47.37ZM162.23 51.6301C162.785 52.4472 163.428 52.6455 164.126 52.1695L164.166 52.1457C164.92 51.638 164.856 50.9954 164.332 50.21L163.666 49.2263L161.571 50.6464L162.23 51.6301Z"/>
<path d="M164.674 55.9298L164.753 55.8901C166.808 54.8112 169.102 55.4855 170.197 57.564C171.292 59.6583 170.569 61.8796 168.506 62.9585L168.427 62.9981C166.372 64.077 164.095 63.4821 162.976 61.3481C161.849 59.2061 162.651 56.9928 164.674 55.9298ZM167.705 61.6495L167.784 61.6098C169.228 60.8483 169.879 59.4838 169.157 58.1114C168.435 56.7389 166.999 56.4533 165.483 57.2466L165.404 57.2863C163.873 58.0876 163.373 59.5472 164.039 60.8245C164.729 62.1493 166.182 62.4508 167.705 61.6495Z"/>
<path d="M173.021 63.6407L173.886 65.8858C174.878 68.4641 173.886 70.2807 171.736 71.1058L171.641 71.1375C169.49 71.9626 167.475 71.2962 166.475 68.7021L165.618 66.4808L173.021 63.6407ZM167.546 68.2499C168.181 69.9079 169.443 70.3363 171.077 69.7096L171.157 69.6778C172.751 69.067 173.434 67.9722 172.767 66.2349L172.458 65.4416L167.229 67.4486L167.546 68.2499Z"/>
<path d="M171.386 73.4618L176.138 72.2242L176.511 73.6601L171.87 74.8739C170.465 75.2388 170.005 75.8417 170.298 76.9762C170.584 78.0789 171.227 78.5072 172.814 78.0947L177.36 76.9127L177.733 78.3486L173.068 79.5623C171.06 80.0859 169.672 79.2926 169.148 77.2618C168.648 75.3419 169.41 73.9775 171.386 73.4618Z"/>
<path d="M174.298 81.4902L174.385 81.4743C176.75 81.1411 178.622 82.5294 178.932 84.711C179.186 86.5118 178.488 88.035 176.663 88.4713L176.448 86.9878C177.472 86.6784 177.924 86.02 177.766 84.8855C177.567 83.5052 176.361 82.7515 174.607 83.0054L174.52 83.0212C172.751 83.2751 171.83 84.2667 172.037 85.7185C172.195 86.8053 172.846 87.4955 173.981 87.4955L174.187 88.9235C172.267 88.9631 171.116 87.7573 170.854 85.893C170.513 83.4258 171.886 81.8313 174.298 81.4902Z"/>
<path d="M178.352 92.2156L178.265 90.0102L179.431 89.9626L179.669 95.8569L178.503 95.9045L178.416 93.6991L171.655 93.9689L171.592 92.4854L178.352 92.2156Z"/>
<path d="M179.487 101.934L179.241 104.329C178.956 107.074 177.258 108.248 174.956 108.01L174.861 108.003C172.568 107.765 171.068 106.265 171.354 103.497L171.6 101.125L179.487 101.934ZM172.521 103.568C172.338 105.329 173.266 106.289 175.012 106.463L175.099 106.471C176.797 106.646 177.9 105.98 178.091 104.131L178.178 103.282L172.608 102.711L172.521 103.568Z"/>
<path d="M178.361 111.017L177.353 115.872L176.21 115.634L176.916 112.223L174.79 111.779L174.219 114.516L173.132 114.293L173.695 111.556L171.426 111.088L170.68 114.682L169.537 114.444L170.584 109.407L178.361 111.017Z"/>
<path d="M171.593 116.42L171.172 117.776C170.379 117.633 169.649 117.744 169.252 119.03C168.99 119.863 169.276 120.6 169.974 120.822C170.672 121.037 171.045 120.775 171.624 119.609C172.426 117.871 173.243 117.189 174.663 117.625C175.901 118.006 176.504 119.355 176.02 120.933C175.512 122.568 174.473 123.313 172.973 123.012L173.37 121.719C174.203 121.83 174.687 121.473 174.957 120.608C175.219 119.751 174.949 119.18 174.362 118.998C173.751 118.807 173.362 118.966 172.767 120.219C171.973 121.925 171.18 122.687 169.664 122.219C168.347 121.814 167.665 120.378 168.189 118.712C168.839 116.634 170.133 116.086 171.593 116.42Z"/>
<path d="M174.06 126.28L173.513 127.661L166.149 124.741L166.697 123.361L174.06 126.28Z"/>
<path d="M168.958 128.359L169.038 128.398C171.148 129.454 171.926 131.675 170.886 133.753C170.125 135.269 168.76 136.268 166.967 135.61L167.625 134.293C168.649 134.602 169.371 134.174 169.831 133.254C170.529 131.865 169.895 130.548 168.339 129.771L168.26 129.731C166.744 128.97 165.348 129.152 164.602 130.644C163.936 131.968 164.467 132.92 165.427 133.452L166.324 131.667L167.316 132.159L165.769 135.245L165.11 134.919C163.15 133.936 162.571 132.111 163.579 130.112C164.729 127.827 166.871 127.312 168.958 128.359Z"/>
<path d="M167.784 139.259L166.816 140.734L160.19 140.385L164.983 143.527L164.245 144.645L157.611 140.298L158.452 139.021L165.491 139.362L160.404 136.03L161.142 134.911L167.784 139.259Z"/>
<path d="M162.396 147.089L159.269 150.929L158.365 150.191L160.563 147.493L158.88 146.121L157.119 148.287L156.262 147.589L158.024 145.423L156.23 143.963L153.913 146.811L153.009 146.073L156.254 142.083L162.396 147.089Z"/>
<path d="M157.437 152.975L155.564 154.863C154.342 156.101 152.978 156.466 151.827 155.331L151.795 155.3C150.914 154.427 151.01 153.427 151.525 152.547L147.526 151.722L148.653 150.587L152.39 151.365L153.049 150.698L150.772 148.445L151.811 147.398L157.437 152.975ZM152.962 152.285C152.263 152.983 152.2 153.649 152.803 154.244L152.835 154.276C153.477 154.919 154.096 154.728 154.763 154.062L155.596 153.221L153.803 151.444L152.962 152.285Z"/>
<path d="M146.931 162.487L145.384 163.645L138.536 158.869L139.758 157.949L141.44 159.139L143.86 157.322L143.178 155.379L144.289 154.546L146.931 162.487ZM144.241 158.393L142.369 159.798L145.511 162.043L144.241 158.393Z"/>
<path d="M141.219 166.525L139.037 167.834C137.513 168.746 136.117 168.667 135.26 167.247L135.236 167.207C134.379 165.771 135.03 164.518 136.482 163.645L137.434 163.074L135.879 160.488L137.14 159.726L141.219 166.525ZM137.053 164.542C136.22 165.041 136.014 165.724 136.466 166.469L136.49 166.509C136.974 167.318 137.64 167.342 138.442 166.858L139.362 166.303L137.973 163.986L137.053 164.542Z"/>
<path d="M134.315 170.507L132.014 171.602C130.411 172.364 129.023 172.157 128.309 170.65L128.293 170.61C127.571 169.095 128.341 167.905 129.88 167.175L130.88 166.699L129.586 163.978L130.919 163.344L134.315 170.507ZM130.356 168.127C129.475 168.548 129.205 169.198 129.586 169.992L129.602 170.031C130.007 170.88 130.665 170.975 131.514 170.571L132.482 170.111L131.316 167.667L130.356 168.127Z"/>
<path d="M127.078 173.776L125.69 174.283L123.357 167.945L120.183 169.111L119.778 168.008L124.341 166.334L127.078 173.776Z"/>
<path d="M120.199 176.14L118.771 176.552L116.573 168.937L118.001 168.524L120.199 176.14Z"/>
<path d="M115.454 173.355L115.47 173.443C115.938 175.783 114.653 177.734 112.487 178.163C110.701 178.52 109.146 177.901 108.607 176.108L110.075 175.815C110.44 176.814 111.13 177.235 112.249 177.012C113.621 176.743 114.296 175.497 113.955 173.76L113.939 173.673C113.59 171.919 112.55 171.055 111.114 171.34C110.035 171.555 109.384 172.253 109.456 173.379L108.043 173.665C107.901 171.753 109.035 170.539 110.884 170.174C113.304 169.682 114.97 170.967 115.454 173.355Z"/>
<path d="M104.647 179.202L102.727 179.369L99.4258 171.705L100.949 171.57L101.751 173.466L104.766 173.205L105.21 171.197L106.591 171.078L104.647 179.202ZM104.512 174.307L102.179 174.514L103.663 178.075L104.512 174.307Z"/>
<path d="M96.8717 178.464L99.0775 178.504L99.0616 179.67L93.1582 179.575L93.1741 178.409L95.3799 178.448L95.491 171.681L96.9748 171.705L96.8717 178.464Z"/>
<path d="M90.8803 179.44L89.3965 179.329L89.9757 171.428L91.4595 171.539L90.8803 179.44Z"/>
<path d="M87.3815 175.204L87.3656 175.291C86.9927 177.576 85.0725 179.004 82.7476 178.623C80.4148 178.242 79.0817 176.322 79.4547 174.03L79.4705 173.942C79.8435 171.658 81.6923 170.19 84.0648 170.579C86.469 170.975 87.7465 172.951 87.3815 175.204ZM80.9861 174.18L80.9702 174.268C80.7084 175.878 81.4066 177.219 82.938 177.465C84.4694 177.718 85.5644 176.735 85.8421 175.053L85.858 174.966C86.1357 173.26 85.2788 171.983 83.8585 171.753C82.3747 171.515 81.2638 172.483 80.9861 174.18Z"/>
<path d="M76.1772 177.235L74.4871 176.743L72.8764 170.309L71.2815 175.815L69.9961 175.442L72.2019 167.826L73.6699 168.254L75.4076 175.085L77.0977 169.246L78.3831 169.619L76.1772 177.235Z"/>
<path d="M65.9805 169.04C65.671 169.754 64.8538 170.095 64.1079 169.77C63.3541 169.436 63.0684 168.611 63.3779 167.897C63.6953 167.175 64.4967 166.834 65.2505 167.167C65.9964 167.493 66.2979 168.318 65.9805 169.04Z"/>
<path d="M60.4032 162.788L59.824 163.788C59.6415 163.558 59.4431 163.336 59.0384 163.106C58.4116 162.741 57.8244 162.836 57.3325 163.685L54.5871 168.437L53.3096 167.691L56.0709 162.907C56.9278 161.416 58.1815 161.233 59.4431 161.963C60.0065 162.297 60.2445 162.55 60.4032 162.788Z"/>
<path d="M53.6985 162.209L50.8579 166.223L49.6439 165.367L52.421 161.448C53.2621 160.258 53.2224 159.504 52.2623 158.822C51.326 158.163 50.5643 158.243 49.6201 159.583L46.9064 163.423L45.6924 162.566L48.4775 158.631C49.6756 156.942 51.2467 156.648 52.9606 157.862C54.5793 158.996 54.8729 160.543 53.6985 162.209Z"/>
<path d="M47.3186 156.886L46.2553 155.95C46.7076 155.284 46.906 154.57 45.8983 153.689C45.2397 153.11 44.4542 153.07 43.9701 153.618C43.4861 154.165 43.5813 154.617 44.4065 155.625C45.6602 157.069 45.9618 158.092 44.9778 159.211C44.1209 160.186 42.645 160.186 41.4072 159.1C40.1218 157.973 39.8679 156.72 40.7566 155.474L41.7722 156.363C41.3358 157.085 41.4548 157.664 42.1372 158.267C42.8117 158.862 43.4464 158.838 43.8511 158.386C44.2717 157.902 44.2875 157.489 43.383 156.426C42.1531 155.006 41.7802 153.967 42.8196 152.777C43.7242 151.746 45.3111 151.706 46.6283 152.856C48.247 154.284 48.2232 155.688 47.3186 156.886Z"/>
<path d="M37.1704 153.332L38.6859 154.935L37.8369 155.736L33.7822 151.452L34.6312 150.651L36.1468 152.253L41.0584 147.604L42.082 148.683L37.1704 153.332Z"/>
<path d="M32.2899 149.643L31.3457 148.493L37.4872 143.471L38.4314 144.622L32.2899 149.643Z"/>
<path d="M29.4343 146.113L28.4345 144.661L31.2672 138.664L26.5381 141.909L25.7764 140.806L32.3146 136.316L33.1795 137.577L30.196 143.963L35.2028 140.52L35.9646 141.623L29.4343 146.113Z"/>
<path d="M22.4357 134.975L21.2773 132.579C20.5155 131.008 20.6266 129.604 22.0866 128.898L22.1263 128.882C23.2451 128.343 24.1496 128.763 24.8082 129.541L26.9268 126.042L27.6251 127.478L25.6414 130.739L26.054 131.588L28.9422 130.191L29.5849 131.516L22.4357 134.975ZM24.5781 130.977C24.1496 130.088 23.5387 129.803 22.7849 130.168L22.7452 130.183C21.9279 130.58 21.8962 131.223 22.3009 132.072L22.8166 133.142L25.0939 132.04L24.5781 130.977Z"/>
<path d="M18.8569 127.248L18.3649 125.86L21.5388 124.733L20.3486 121.378L17.1747 122.504L16.6748 121.108L24.1493 118.458L24.6492 119.855L21.4753 120.981L22.6655 124.337L25.8394 123.21L26.3314 124.599L18.8569 127.248Z"/>
<path d="M19.7938 117.617L19.7065 117.633C17.4451 118.141 15.4059 116.895 14.8901 114.595C14.3743 112.286 15.6518 110.335 17.9291 109.827L18.0164 109.811C20.2778 109.304 22.3249 110.47 22.8486 112.818C23.3803 115.198 22.0234 117.118 19.7938 117.617ZM18.3576 111.303L18.2703 111.319C16.6833 111.676 15.6994 112.826 16.0406 114.341C16.3818 115.856 17.699 116.507 19.3653 116.134L19.4526 116.118C21.1427 115.737 22.0075 114.468 21.6902 113.064C21.3569 111.596 20.0397 110.93 18.3576 111.303Z"/>
<path d="M13.5961 106.416L13.3818 104.496L20.9754 101.029L21.142 102.553L19.2615 103.393L19.5947 106.4L21.6101 106.805L21.7609 108.185L13.5961 106.416ZM18.4918 106.186L18.2379 103.862L14.7069 105.416L18.4918 106.186Z"/>
<path d="M13.0407 99.4982L13.0011 97.0945C12.9535 94.3337 14.5087 92.9613 16.8177 92.9296H16.9208C19.2298 92.8899 20.8882 94.2068 20.9358 96.9913L20.9755 99.3792L13.0407 99.4982ZM19.7694 97.0468C19.7376 95.2698 18.7061 94.4369 16.9526 94.4686H16.8653C15.1593 94.5003 14.1357 95.2857 14.1675 97.15L14.1833 98.0068L19.7853 97.9116L19.7694 97.0468Z"/>
<path d="M13.1758 90.288L13.6677 85.3616L14.8262 85.4806L14.4771 88.9473L16.6433 89.1536L16.921 86.377L18.0239 86.4881L17.7462 89.2646L20.0552 89.4947L20.4202 85.8455L21.5787 85.9645L21.0708 91.0813L13.1758 90.288Z"/>
<path d="M19.3416 84.1794L19.6193 82.7912C20.4207 82.8467 21.1349 82.6642 21.3967 81.3473C21.5633 80.4906 21.2063 79.7845 20.4922 79.6417C19.778 79.4989 19.4289 79.8083 18.9766 81.0221C18.3577 82.8308 17.6118 83.6003 16.1598 83.3068C14.8902 83.0529 14.1444 81.7757 14.4697 80.1574C14.803 78.4835 15.7631 77.6347 17.2786 77.7774L17.0167 79.1023C16.1757 79.0785 15.7313 79.4831 15.5568 80.3716C15.3822 81.2521 15.7154 81.7916 16.3106 81.9106C16.9374 82.0375 17.3103 81.8392 17.7705 80.5302C18.3815 78.7532 19.0956 77.9123 20.6429 78.2217C21.9918 78.4914 22.817 79.84 22.4758 81.5536C22.0632 83.6797 20.8333 84.3619 19.3416 84.1794Z"/>
<path d="M21.0472 72.9542C20.3093 72.7083 19.8887 71.9308 20.1426 71.1613C20.3965 70.3839 21.198 70.011 21.9359 70.257C22.6817 70.5029 23.0943 71.2724 22.8404 72.0498C22.5865 72.8194 21.801 73.2001 21.0472 72.9542Z"/>
<path d="M19.8643 63.014L20.9037 60.8483C22.1019 58.3652 24.1014 57.8337 26.1803 58.8332L26.2676 58.8729C28.3465 59.8725 29.2431 61.7923 28.037 64.2991L27.0055 66.449L19.8643 63.014ZM26.9738 63.839C27.7434 62.2445 27.1959 61.0307 25.6169 60.2691L25.5376 60.2295C23.9983 59.4917 22.7366 59.7456 21.9273 61.4194L21.5544 62.1889L26.6008 64.6165L26.9738 63.839Z"/>
<path d="M23.9912 54.8747L26.5779 50.6464L27.5698 51.2572L25.7527 54.2242L27.6094 55.3586L29.0694 52.9787L30.0137 53.5578L28.5537 55.9377L30.5294 57.1436L32.4417 54.0179L33.4335 54.6288L30.7516 59.0078L23.9912 54.8747Z"/>
<path d="M31.0136 51.1778L30.9422 51.1223C29.0458 49.6784 28.7205 47.362 30.0535 45.6088C31.1565 44.1649 32.7355 43.5938 34.3224 44.5854L33.4179 45.7754C32.4816 45.2676 31.6881 45.4104 30.9978 46.3148C30.1488 47.4254 30.4662 48.8058 31.8706 49.8847L31.942 49.9402C33.3623 51.0191 34.7192 50.9795 35.5999 49.8212C36.2664 48.9486 36.2982 47.9966 35.4968 47.1954L36.3696 46.0451C37.7423 47.3858 37.6947 49.0438 36.5521 50.5431C35.0524 52.5106 32.9497 52.6533 31.0136 51.1778Z"/>
<path d="M33.9326 41.0473L37.3287 37.4298L38.1777 38.2231L35.7973 40.7617L37.3842 42.2531L39.2965 40.2143L40.1058 40.9759L38.1936 43.0147L39.8837 44.6013L42.399 41.9279L43.248 42.7212L39.725 46.4735L33.9326 41.0473Z"/>
<path d="M39.3125 35.4386L40.8201 34.1614L45.9142 36.938L43.9623 31.4959L45.446 30.2345L50.5798 36.2795L49.4531 37.2315L45.573 32.67L47.6519 38.7626L47.2869 39.072L41.5739 36.0653L45.454 40.6268L44.4383 41.4915L39.3125 35.4386Z"/>
<path d="M47.9854 28.4099L50.3499 26.8391C51.7067 25.9347 52.8493 25.9189 53.6507 27.1168L53.6745 27.1564C54.111 27.8149 54.1586 28.5209 53.5635 29.2508C54.587 28.8462 55.3408 29.0524 55.9201 29.933L55.9439 29.9727C56.7612 31.2023 56.3565 32.3605 54.9124 33.3204L52.3733 35.0102L47.9854 28.4099ZM52.0479 29.7188C52.8573 29.1794 52.9763 28.6717 52.5319 27.9973L52.5081 27.9577C52.0876 27.331 51.556 27.2992 50.7704 27.8228L49.7707 28.4892L51.0323 30.3852L52.0479 29.7188ZM54.1824 32.543C54.9997 31.9956 55.1345 31.3768 54.6823 30.6946L54.6585 30.6549C54.2062 29.9727 53.5952 29.8854 52.6906 30.4883L51.5877 31.2261L52.9922 33.3363L54.1824 32.543Z"/>
<path d="M55.0312 23.9039L59.4033 21.5636L59.9508 22.587L56.88 24.2291L57.9036 26.1489L60.3713 24.832L60.895 25.8078L58.4273 27.1247L59.5223 29.1714L62.7597 27.442L63.3072 28.4654L58.7685 30.8929L55.0312 23.9039Z"/>
<path d="M61.8867 20.3974L64.3465 19.3741C65.9572 18.7077 67.3537 18.8981 67.9727 20.3895L67.9885 20.4292C68.4646 21.5795 67.9885 22.46 67.1792 23.0709L70.5514 25.3794L69.0756 25.9903L65.9334 23.8245L65.0606 24.1895L66.2905 27.1485L64.9257 27.7117L61.8867 20.3974ZM65.7509 22.7694C66.6634 22.3886 66.9808 21.8016 66.6634 21.0242L66.6476 20.9845C66.2984 20.1357 65.6557 20.0722 64.7829 20.4292L63.6879 20.8814L64.6559 23.2137L65.7509 22.7694Z"/>
<path d="M74.456 23.8245L76.5666 20.0166C77.0982 19.0567 77.368 18.2634 77.1776 17.5336C76.9951 16.8117 76.4714 16.4706 75.7255 16.661C74.9638 16.8593 74.5909 17.4225 74.7416 18.5411L73.4324 18.8743C73.0991 17.1766 73.8609 15.947 75.4796 15.5345C77.0665 15.1299 78.2488 15.7962 78.5979 17.1528C78.8518 18.1524 78.4868 19.1757 77.8044 20.2864L76.5269 22.3886L79.796 21.5477L80.0817 22.6504L74.5274 24.0784L74.456 23.8245Z"/>
<path d="M80.9222 18.6204L80.8587 18.2237C80.4937 15.8438 81.5887 14.1858 83.5565 13.8843C85.5402 13.5829 87.0557 14.8284 87.4128 17.1845L87.4763 17.5811C87.8413 19.9373 86.8415 21.627 84.7943 21.9443C82.8027 22.2458 81.2872 20.9765 80.9222 18.6204ZM86.0242 17.8033L85.9687 17.4225C85.7147 15.7565 84.9213 14.8442 83.7469 15.0267C82.5647 15.2091 82.0648 16.2484 82.3266 17.9937L82.3901 18.3903C82.6599 20.1673 83.4772 20.9606 84.6515 20.7782C85.8576 20.5878 86.294 19.5723 86.0242 17.8033Z"/>
<path d="M89.2219 20.9528L92.0467 17.6367C92.7608 16.8038 93.1734 16.0819 93.1337 15.3282C93.0941 14.5825 92.6497 14.1462 91.888 14.1859C91.1025 14.2255 90.6264 14.7094 90.555 15.8359L89.2061 15.9073C89.214 14.1779 90.2058 13.1228 91.8801 13.0356C93.5225 12.9483 94.5461 13.8368 94.6175 15.233C94.6731 16.2643 94.1097 17.1925 93.2131 18.1524L91.5389 19.9611L94.9032 19.7866L94.9667 20.929L89.2378 21.2304L89.2219 20.9528Z"/>
<path d="M96.5861 17.129L96.6019 16.7324C96.713 14.3286 98.1016 12.9166 100.101 13.0038C102.109 13.0911 103.346 14.6142 103.243 16.9942L103.227 17.3908C103.124 19.7707 101.815 21.2304 99.7441 21.1432C97.7207 21.048 96.4829 19.509 96.5861 17.129ZM101.752 17.3274L101.767 16.9386C101.847 15.2568 101.244 14.2097 100.061 14.1541C98.8633 14.0986 98.1651 15.0268 98.0857 16.7879L98.0699 17.1846C97.9905 18.9774 98.6332 19.9215 99.8234 19.9691C101.045 20.0246 101.672 19.1202 101.752 17.3274Z"/>
<path d="M108.471 18.4935C108.606 17.7319 109.312 17.2004 110.114 17.3353C110.923 17.4781 111.399 18.2159 111.272 18.9774C111.137 19.7549 110.439 20.2705 109.63 20.1357C108.828 19.9929 108.337 19.271 108.471 18.4935Z"/>



            </svg>
        </Wrap>
    );
}

const Wrap = styled.div`
    width: 192px;
    height: 192px;
    position: absolute;
    right: -1em;
    top: calc(-8em + 40px);
    animation: rotation 60s infinite linear;
    svg {
        fill: #FFFFFF;
    }
    @keyframes rotation {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(359deg);
        }
    }
    @media (min-width: 768px) {
        right: 2em;
    }
`;





import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";

import "./reset.css";
import "./fonts.css";
import { GlobalStyles } from "./GlobalStyles";
import { App } from "./App";

ReactDOM.render(
  <Router>
    <GlobalStyles />
    <App />
  </Router>,
  document.getElementById("root")
);


import { createGlobalStyle } from "styled-components"

const defaultSansStack = `system, -apple-system, ".SFNSText-Regular", "San Francisco", "Roboto", "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif`;
const bold = `Graphik-Bold, ${defaultSansStack}`;
const medium = `Graphik-Medium, ${defaultSansStack}`;
const regular = `Graphik-Regular, ${defaultSansStack}`;

export const Colors = {
    "background": "#101010",
    "foreground": "#BBBBBB",
    "white": "#FFFFFF",
    "green": "#37C98C",
    "greenShade": "#052C40",
    "blue": "#009DF9",
    "blueShade": "#052C40",
    "yellow": "#FFC25E",
    "yellowShade": "#2F2008",
    "purple": "#C789ED",
    "purpleShade": "#2F2008",
}

export const GlobalStyles = createGlobalStyle`

html,
body {
    margin: 0;
    padding: 0;
}

body {
    font-family: ${regular};
    font-size: 22px;
    line-height: 1.5;

    background-color: ${Colors.background};
    color: ${Colors.foreground};
}

@media (min-width: 480px) {
    body {
        font-size: 28px;
    }
}

::selection {
  color: ${Colors.white};
  background: ${Colors.background};
}


code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li {
    font-weight: normal;
    margin: 0;
    padding: 0;
}

h1 {
    font-family: ${bold};
    color: ${Colors.white};
    font-size: 2em;
    line-height: 1;
    margin-bottom: 1em;

    @media (min-width: 480px) {
        font-size: 3em;
    }
}

h2 {
    color: ${Colors.white};
    font-family: ${medium};
    margin-bottom: 0.5em;
}

h3 {
    font-family: ${regular};
    margin-bottom: 0.5em;
    font-size: 1.714em;
}

h6,.tag {
    font-family: ${medium};
    font-size: 0.571em;
}

p {
    margin: 1.5em 0;
}

li {
    list-style: none;
}

.frame {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
}

`;
import React from 'react';
import styled from "styled-components";
import { motion } from "framer-motion";

const w = 300;
const h = 300;
const p = 40;
const r = 7.5

export const FigureLearningSynths = () => {
    return (
        <Wrap
            animate={{
                rotateX: [0, r, -r, -r, r, 0, 0],
                rotateY: [0, r, r, -r, -r, 0, 0],
            }}
            transformPerspective={550}
            transition={{
                duration: 6, ease: "easeInOut", repeat: Infinity,
                times: [0, 0.2, 0.4, 0.6, 0.8, 0.9, 1.0]
            }}
        >
            <Outline />
            <Thumb
                animate={{
                    x: [w / 2, p, p, w - p, w - p, w / 2, w / 2],
                    y: [h / 2, h - p, p, p, h - p, h / 2, h / 2],
                }}
                transition={{
                    duration: 6, ease: "easeInOut", repeat: Infinity,
                    times: [0, 0.2, 0.4, 0.6, 0.8, 0.9, 1.0]
                }}
            >
                <ThumbContent>
                <ThumbFill
                    animate={{
                        opacity: [0, 1.0, 1.0, 1.0, 1.0, 0.0, 0],
                    }}
                    transition={{
                        duration: 6, ease: "easeInOut", repeat: Infinity,
                        times: [0, 0.1, 0.4, 0.6, 0.8, 0.9, 1.0]
                    }}
                />
                    <ThumbRing />
                </ThumbContent>
            </Thumb>
        </Wrap>
    )
}

const padBg = "#292929";

const Wrap = styled(motion.div)`
  position: relative;
  width: 300px;
  height: 300px;
`

const Outline = styled.div`
  border: 6px solid #00D692;
  background-color: ${padBg};
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const Thumb = styled(motion.div)`
    width: 0;
    height: 0;
    position: absolute;
    top: 0;
    left: 0;
`;

const ThumbContent = styled(motion.div)`
    width: 29px;
    height: 29px;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
`;

const ThumbRing = styled(motion.div)`
    width: 27px;
    height: 27px;
    position: absolute;
    border-radius: 14.5px;
    border-width: 4px;
    border-color: #00D692;
    border-style: solid;
    top: 2px;
    left: 2px;
`;

const ThumbFill = styled(motion.div)`
    width: 27px;
    height: 27px;
    position: absolute;
    top: 2px;
    left: 2px;
    background-color: #00D692;
    border-radius: 14.5px;
    opacity: 0;
`;
import React from 'react';
import styled from "styled-components";
import { motion } from "framer-motion";

const linesBgColor = "#101010";
const notesBgColor = "#292929";
const vertLineWidth = 4;
const gridWidth = 302;
const gridHeight = 299;


export const FigureAbletonApp = () => {

  return (
    <Wrap>
        <Viz>
            <NotesBg />
            <VertLine x={47} />
            <VertLine x={98} />
            <VertLine x={149} />
            <VertLine x={200} />
            <VertLine x={251} />
            <BeatLine y={49} />
            <BeatLine y={99} />
            <BeatLine y={149} />
            <BeatLine y={199} />
            <BeatLine y={249} />
            
            <Note x={0}   y={0} velocity={1.0} />
            <Note x={255} y={0} velocity={0.15} />

            <Note x={51}  y={50} velocity={0.5} />
            <Note x={204} y={50} velocity={1.0} />

            <Note x={102} y={100} velocity={1.0} />
            <Note x={255} y={100} velocity={0.15} />
            
            <Note x={0} y={150} velocity={1.0} />
            <Note x={204} y={150} velocity={1.0} />

            <Note x={153} y={200} velocity={.25} />
            <Note x={255} y={200} velocity={0.15} />

            <Note x={0} y={250} velocity={1.0} />
            <Note x={204} y={250} velocity={1.0} />
            
            <Playhead
                animate={{ y: [0, gridHeight] }}
                transition={{ duration: 2, ease: "linear", repeat: Infinity, }}
            />
        </Viz>
    </Wrap>
  )
}

const fadeOutTime = 0.2;

const Note = (props) => {
    return (
      <NoteWrap x={props.x} y={props.y}>
          <NoteBg velocity={props.velocity}/>
          <NoteActive
            animate={{ opacity: [0, 0, props.velocity+0.2, 0, 0] }}
            transition={{ duration: 2, ease: "linear", repeat: Infinity, times:[0, props.y/gridHeight, props.y/gridHeight, props.y/gridHeight+fadeOutTime, 1.0] }}
          />
          <NoteHandle />
      </NoteWrap>
    )
  }
  
const Wrap = styled.div`
  position: relative;
  width: 302px;
  height: 299px;
`
const Viz = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${linesBgColor};
`
const NotesBg = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-color: ${notesBgColor};
`

const VertLine = styled.div`
    position: absolute;
    top: 0;
    width: ${vertLineWidth}px;
    left: ${props => props.x}px;
    bottom: 0;
    background-color: ${linesBgColor};
`
const BeatLine = styled.div`
    position: absolute;
    top: ${props => props.y}px;
    width: 100%;
    left: 0px;
    height: 1px;
    background-color: ${linesBgColor};
`
const NoteWrap = styled.div`
    position: absolute;
    left: ${props => props.x}px;
    top: ${props => props.y}px;
    width: 47px;
    height: 49px;
`
const NoteBg = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-color: #00AAFF;
    border-radius: 2px;
    opacity: ${props => props.velocity};
`
const NoteActive = styled(motion.div)`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-color: #80D5FF;
    border-radius: 2px;
    opacity: 0;
`
const NoteHandle = styled.div`
    position: absolute;
    width: 13px;
    height: 1px;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #000;
`
const Playhead = styled(motion.div)`
    position: absolute;
    width: 100%;
    height: 1.5px;
    left: 0;
    top: 0px;
    background-color: #00AAFF;
`
import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { motion } from "framer-motion";

export const TagsOverlay = (props) => {

    function escapeKeyHandler(event) {
        if (event.keyCode === 27) {
            props.setTagsOpen(false)
        }
    }

    React.useEffect(() => {
        window.addEventListener('keydown', escapeKeyHandler);
        return () => {
            window.removeEventListener('keydown', escapeKeyHandler);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const GlobalStyles = createGlobalStyle`
        body
        {
            /* overflow: hidden; */
        }
    `

    const pageVariants = {
        initial: {
            opacity: 0,
        },
        in: {
            opacity: 1,
        },
        out: {
            opacity: 0,
        }
    };

    const pageTransition = {
        type: "tween",
        ease: "easeInOut",
        duration: 0.25
    };


    return (
        <Overlay
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}
            key={"tagsoverlay"}
            onClick={() => props.setTagsOpen(false)}>
                <GlobalStyles />
        </Overlay>

    )
};

const Overlay = styled(motion.div)`
     position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,.75);
    z-index: 1;
    cursor: pointer;
`;


import React from "react";
import { motion } from "framer-motion";
import styled, { createGlobalStyle } from 'styled-components';
import { Link, useHistory } from "react-router-dom";

export const Page = (props) => {

    const history = useHistory();

    function escapeKeyHandler(event) {
        if (event.keyCode === 27) {
            history.push("/")
        }
    }

    React.useEffect(() => {
        window.addEventListener('keydown', escapeKeyHandler);
        return () => {
            window.removeEventListener('keydown', escapeKeyHandler);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const GlobalStyles = createGlobalStyle`
        body
        {
            overflow: hidden;
        }
    `

    const inTransition = {
        type: "spring",
        damping: 15,
        stiffness: 100
    }

    const outTransition = {
        type: "spring",
        damping: 10,
        stiffness: 100
    }

    const pageVariants = {
        initial: {
            opacity: 0,
            y: 200,
        },
        in: {
            opacity: 1,
            y: 0,
            transition: inTransition,
        },
        out: {
            opacity: 0,
            y: 200,
            transition: outTransition,
        }
    };

    return (
        <PageWrap
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            key={props.pKey}
        >
            <GlobalStyles />
            <Overlay to="/" />
            <PageContent background={props.background} foreground={props.foreground}>
                <Close to="/" foreground={props.foreground}>
                    <svg width="64" height="64" viewBox="0 0 64 64">
                        <circle cx="32" cy="32" r="32" />
                        <path d="M20 20L44 44" />
                        <path d="M44 20L20 44" />
                    </svg>
                </Close>
                {props.children}
                <Close to="/" foreground={props.foreground} className="bottom">
                    <svg width="64" height="64" viewBox="0 0 64 64">
                        <circle cx="32" cy="32" r="32" />
                        <path d="M20 20L44 44" />
                        <path d="M44 20L20 44" />
                    </svg>
                </Close>
            </PageContent>
        </PageWrap>
    )
}

const PageWrap = styled(motion.div)`
   position: fixed;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   overflow-y: auto;
`;

const Overlay = styled(Link)`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
`;

const PageContent = styled(motion.div)`
    position: relative;
    background-color: ${props => props.background};
    margin: 4em 0 4em 0;
    padding: 8em 2em;
    color: ${props => props.foreground};

    @media (min-width: 800px) {
        margin: 4em 4em 4em 0;
        padding: 8em 4em;
    }
    

    a {
        color: ${props => props.foreground};
    }
`;

const Close = styled(Link)`
    position: absolute;
    top: 16px;
    right: 24px;
    width: 48px;
    height: 48px;
    transform: scale(0.75);

    @media (min-width: 800px) {
        top: 32px;
        right: 32px;
        width: 64px;
        height: 64px;
        transform: scale(1.0);
    }

    circle {
        fill: rgba(0, 0, 0, 0.1);
    }

    path {
        stroke: ${props => props.foreground};
        stroke-width: 2px;
        stroke-linecap: round;
    }

    &:hover {
        circle {
            fill: rgba(0, 0, 0, 0.25);
        }
    }

    &.bottom {
        top: auto;
        bottom: 32px;
    }
`;

